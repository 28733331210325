#invitatie .card {
    background-image: linear-gradient(90deg, #eef2f5 , #78B497 80%);
    color: black;
}

#invitatie .card h4 {
    color: aliceblue;
    text-shadow: 1px 1px 2px rgb(26, 25, 25);
    
}

#img_noi {
    width: 250px;
    border-radius: 100%;
}

#img_nasi {
    width: 250px;
    border-radius: 100%;
}